<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="银行卡名称:">
                    <el-input v-model="queryParams.bankname" placeholder="请输入银行卡名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="下线" :value="1"></el-option>
                        <el-option label="上线" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增</el-button>
                <el-button type="primary" @click="sync" v-if="isSync">同步</el-button>
                <el-button type="primary" v-else><i class="el-icon-loading"></i>同步中</el-button>
            </div>
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list">
                <el-table-column label="id" prop="id" align="center" width="100"></el-table-column>
                <el-table-column label="银行卡编号" prop="code" align="center" width="180"></el-table-column>
                <el-table-column label="银行卡名称" prop="bankname" align="center"></el-table-column>
                <el-table-column label="银行卡logo" align="center" width="180" prop="logo">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px;border-radius:25px;margin-top: 10px" :class="`column${scope.$index}`" :src="scope.row.logo" :preview-src-list="[scope.row.logo]">
                            <div slot="error" class="image-slot">
                                <el-image :src="defaultImg" class="center_image"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>

                <el-table-column label="描述" prop="content" align="center"></el-table-column>
                <el-table-column label="排序" prop="rate" align="center" width="100"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="100">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" class="dialog_box">
            <el-form class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading" label-position="left" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
                <el-form-item label="银行卡名称" prop="bankname">
                    <el-input type="text" auto-complete="off" v-model="form.bankname" placeholder="请输入银行卡名称"></el-input>
                </el-form-item>
                <el-form-item label="银行卡编号" prop="code">
                    <el-input type="text" auto-complete="off" v-model="form.code" placeholder="请输入银行卡编号"></el-input>
                </el-form-item>
                <!-- //图片上传 -->
                <el-form-item label="银行卡logo" prop="logo">
                    <el-input v-model="form.logo" placeholder="请输入银行卡logo链接"></el-input>
                    <el-button @click.prevent="choiceImg">选择图片</el-button>
                    <el-upload class="avatar-uploader" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header" style="margin-top:30px;">
                        <img v-if="form.logo" :src="form.logo" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="span_gray">建议尺寸126*36像素</span>
                </el-form-item>
                <el-form-item label="描述" prop="content">
                    <el-input type="text" auto-complete="off" v-model="form.content" placeholder="请输入描述内容"></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="rate">
                    <el-input type="text" auto-complete="off" v-model="form.rate" placeholder="请输入排序值"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">提 交</el-button>
            </div>
        </el-dialog>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import {request} from '@/common/request';
import {deal} from '@/common/main';
import Picture from '@/components/Picture';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";

export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination,
        Picture
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            isSync: true,
            defaultImg: require('../../assets/img/zhan.jpeg'),
            header: {},
            confirmVisible: false,
            confirmContent: '',
            menuList: ['资金', '银行卡管理'],
            loading: false,
            queryParams: {
                name: '',
                page: 1,
                pagesize: 10,
                platform: 'admin'
            },
            pageInfo: {},
            addVisible: false,
            title: '添加银行卡',
            rules: {
                bankname: [{
                    required: true,
                    message: '银行卡名称不能为空',
                    trigger: 'blur'
                }],
                content: [{
                    required: true,
                    message: '描述内容不能为空',
                    trigger: 'blur'
                }],
                code: [{
                    required: true,
                    message: '银行卡编号不能为空',
                    trigger: 'blur'
                }],
                logo: [
                    { validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }
                ],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: ['blur', 'change']
                }],
            },
            form: {
                state: 1,
                name: '',
                time: null
            }
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData()
    },
    methods: {
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;
        },
        choiceImg() {
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, 'logo', url);
        },
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        // 图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                this.$set(this.form, 'logo', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            const _this = this
            return deal.imageFormat(file, _this)
        },
        cellstyle() {
            return "text-align: center";
        },
        //银行卡列表
        loadData() {
            // this.loading = true;
            request.get('/wallet/bank/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //新增编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/wallet/bank/add' : '/wallet/bank/edit';
                        //处理时间
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.loadData();
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },

        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        // 编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑银行卡';
            this.current = row.id;
            const item = this.pageInfo.list[index];
            let time = [item.ctime, item.etime]
            //处理时间
            this.form = Object.assign({}, item);
            this.$set(this.form, 'ctime', time);
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        // 新增
        add() {
            this.mode = 'add';
            this.title = '新增银行卡';
            this.form = {
                state: 2
            };
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.confirmVisible = true;
        },
        // 确定下线
        stateData() {
            request.post('/wallet/bank/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 同步
        sync() {
            this.isSync = false
            request.get('/wallet/bank/sync').then(ret => {
                if (ret.code == 1) {
                    this.isSync = true
                    this.$message.success('同步成功');
                    this.loadData();
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
}
</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 126px;
    height: 36px;
    line-height: 36px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 126px;
    height: 36px;
    line-height: 36px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}
.dialog_box .el-button {
    margin-left: 20px;
}
</style>
